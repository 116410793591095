/**
 * Mixin to check user role or permission from spatie/laravel-permission
 */
import {usePage} from '@inertiajs/vue3';

const permissions = {
    methods: {
        $authenticated() {
            return !!usePage().props?.user;
        },
        $role(role) {
            const roles = usePage().props?.user?.roles;

            return roles.includes(role);
        },
        $permission(permission) {
            const permissions = usePage().props?.user?.permissions;

            return permissions.includes(permission);
        },
    },
};

export default permissions;
