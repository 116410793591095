import {usePage} from '@inertiajs/vue3';
import moment from 'moment/moment';

export default {
    formatCurrency(value, currency) {
        currency = currency || 'EUR';
        // A little hacky here
        const locale = currency === 'EUR' ? 'it-IT' : 'en-US';
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
        });
        return formatter.format(value);
    },
    formatNumber(value, minDigits, maxDigits) {
        const formatter = new Intl.NumberFormat('it-IT', {
            style: 'decimal',
            minimumFractionDigits: minDigits || 2,
            maximumFractionDigits: maxDigits || 2,
        });
        return formatter.format(value);
    },
    formatDate(value, format) {
        if (value) {
            return moment(String(value)).format(format || 'YYYY-MM-DD hh:mm');
        }
        return value;
    },
    formattedDateTime(value, locale) {
        if (value) {
            locale = usePage().props.locale || locale || navigator.language;
            const formatter = new Intl.DateTimeFormat(locale, {
                dateStyle: 'short',
                timeStyle: 'short',
                // timeZone: 'UTC',
            });
            return formatter.format(new Date(value));
        }
        return value;
    },
    compareDates(date1, date2) {
        const firstDate = moment(date1).format('YYYY-MM-DD');
        const secondDate = moment(date2).format('YYYY-MM-DD');
        if (firstDate === secondDate) return 0;
        return firstDate < secondDate ? -1 : 1;
    },
};
